import React, { useState, useEffect } from "react";
import serviceData from '../../../data/Service.json'; // Ensure this points to your actual data file

const ServiceDetailsAbout = ({ id }) => { // Accept id as a prop
  const [service, setService] = useState(null);


  useEffect(() => {
    const foundService = serviceData.services.find(s => `${s.id}` === id);

    if (foundService) {
      setService(foundService);
    } else {
      console.log("Service not found for ID:", id);
    }
  }, [id]);
  if (!service) return <div>Loading...</div>; // Handle loading or not found

  return (
    <>
      <div className="tp-service-page pt-140 pb-140">
        <div className="container">
          <div className="row pb-30">
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/service-blog-1.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/service-blog-2.jpg" alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-sv__content">
                <h3 className="tp-sv__title">{service.title}</h3>
                <p>{service.description}</p>
                <h3>{service.successTitle}</h3>
                <p>{service.successDescription}</p>

              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default ServiceDetailsAbout;

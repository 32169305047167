import React from "react";
import { Link } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";


const Footer = () => {
  return (
    <>
      {/* <!-- footer area start --> */}
      <footer>
        <div className="tp-footer__area black-bg">
          <div className="tp-footer">
            {/* <!-- main-footer start  --> */}
            <div className="tp-footer__main">
              <div className="container">
                <div className="tp-footer-border pt-60 pb-30">
                  <div className="row d-flex justify-content-between">

                    <div className="col-lg-3 col-md-6 ">
                      <div className="tp-footer__widget tp-footer__2  pb-30">
                        <h3 className="tp-footer__widget-title text-white">Quick Links</h3>
                        <ul>
                          <li><Link to="/">Home</Link></li>
                          <li><Link to="/about">About Us</Link></li>
                          <li><Link to="/service">Service</Link></li>
                          <li><Link to="/contact">Contact Us</Link></li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget tp-footer__2 footer-col-2-3 pb-30">
                        <h3 className="tp-footer__widget-title text-white">Follow Us</h3>
                        <ul>
                          <li><h5>Stay Connected With Us On Social Media</h5></li>
                          <Link
                            to="https://www.instagram.com/links.and.likes?igsh=b2RoaHc0Zzdjbm50"
                            target="#"
                            className="d-inline-block fs-2"
                            style={{ transition: "transform 0.2s ease-in-out, color 0.2s ease-in-out", color: "initial" }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.transform = "scale(1.2)";
                              e.currentTarget.style.color = "black";
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.transform = "scale(1)";
                              e.currentTarget.style.color = "white";
                            }}
                          >
                            <FaInstagram />
                          </Link>

                        </ul>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            {/* <!-- main footer end  --> */}
          </div>
        </div>

        {/* <!-- footer copy right --> */}
        <div className="top-footer-copyright pt-30 black-bg text-center">
          <div className="container">
            <div className="">
              <div className="">
                <div className="tp-copyrigh-text mb-30 pb-30">
                  <span>
                    © {new Date().getFullYear()} Links & Likes - Business Consulting . All Rights Reserved.
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>
        {/* <!-- footer copyright end  --> */}
      </footer>
      {/* <!-- footer area end --> */}
    </>
  );
};

export default Footer;

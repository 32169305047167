import React from "react";

const TopFooter = () => {
  return (
    <>
      <div className="tp-footer__area black-bg">
        <div className="tp-footer">
          {/* <!-- footer top --> */}
          <div className="tp-footer__top pt-90 pb-60">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <h3 className="tp-footer__top-title">
                    Have a project in mind? Let’s work together.
                  </h3>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- footer top end  --> */}
        </div>
      </div>
    </>
  );
};

export default TopFooter;

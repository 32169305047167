import React, { useState } from "react";
import { Link } from "react-router-dom";
import menu_data from "./MobileData";

const MobileMenus = ({ closeMenu }) => {
  const [navTitle, setNavTitle] = useState("");

  const openMobileMenu = (menu) => {
    setNavTitle(navTitle === menu ? "" : menu);
  };

  return (
    <nav className="mean-nav">
      <ul>
        {menu_data.map((menu, i) => (
          <React.Fragment key={i}>
            {menu.hasDropdown ? (
              <li className="has-dropdown">
                <Link to={menu.link} onClick={closeMenu}>
                  {menu.title}
                </Link>
                <ul
                  className="submenu"
                  style={{
                    display: navTitle === menu.title ? "block" : "none",
                  }}
                >
                  {menu.submenus.map((sub, i) => (
                    <li key={i}>
                      <Link to={sub.link} onClick={closeMenu}>
                        {sub.title}
                      </Link>
                    </li>
                  ))}
                </ul>
                <a
                  className={`mean-expand ${navTitle === menu.title ? "mean-clicked" : ""
                    }`}
                  to="#"
                  onClick={() => openMobileMenu(menu.title)}
                  style={{ fontSize: "18px" }}
                >
                  <i className="fal fa-plus"></i>
                </a>
              </li>
            ) : (
              <li>
                <Link to={menu.link} onClick={closeMenu}>
                  {menu.title}
                </Link>
              </li>
            )}
          </React.Fragment>
        ))}
      </ul>
    </nav>
  );
};

export default MobileMenus;

import React from "react";
import { Link } from "react-router-dom";



const Menus = () => {
  return (
    <ul className="d-flex justify-content-center">
      <li className="main-menu">
        <Link className="hover-text-black" to="/">Home</Link>

      </li>
      <li>
        <Link className="hover-text-black" to="/about">About us</Link>
      </li>
      <li>
        <Link className="hover-text-black" to="/service">Service</Link>
      </li>
      <li>
        <Link className="hover-text-black" to="/contact">contact</Link>
      </li>
    </ul>
  );
};

export default Menus;

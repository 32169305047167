import React from "react";
import { Link } from "react-router-dom";
import img from "../../img/kristina2.jpeg"

const TopAbout = () => {
  return (
    <>
      {/* <!-- about area start --> */}
      <div className="tp-about-area pt-140">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-lg-6">
              <div className="tp-about-left pr-90">
                <div className="tp-section-wrapper pt-5">
                  <span className="tp-section-subtitle">
                    We grow together online
                  </span>
                  <h2 className="tp-section-title pt-25 mb-30">
                    Don’t worry, your project is in the right hands
                  </h2>
                  <p>
                    At our agency, we excel in crafting tailored strategies that elevate your digital presence. Whether it's through <strong>Social Media Management</strong>, where we bring the power of words alive to enhance your engagement across platforms like Facebook, Instagram, and LinkedIn, or <strong>PPC Advertising</strong> to optimize your ROI through targeted ads on Google and other platforms, we handle everything from strategy development to content creation and analytics.
                  </p>
                  <p className="pt-10">
                    Our services extend to <strong>Content Marketing</strong>, where we create relevant and high-quality content, and <strong>Web Design & Development</strong>, where we design custom websites that reflect your brand and drive sales. We also specialize in <strong>Influencer Marketing Campaigns</strong>, connecting you with influencers who resonate with your audience, and <strong>Email Marketing</strong>, crafting intelligent campaigns that keep you in touch with your clients. Finally, our expertise in <strong>User-Generated Content (UGC) Videos</strong> ensures that authentic customer experiences are highlighted, building trust and driving engagement.
                  </p>

                </div>
                <div className="mt-45 mb-30">
                  <Link to="/about">
                    <div className="tp-theme-btn d-flex align-items-center">
                      <div className="tp-btn-inner">
                        <p className="mb-0">Let’t Talk</p>
                        <b>Get in Touch</b>
                        <span className="d-inline-block">
                          <svg
                            width="46"
                            height="8"
                            viewBox="0 0 46 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M45.3536 4.35355C45.5488 4.15829 45.5488 3.84171 45.3536 3.64645L42.1716 0.464466C41.9763 0.269204 41.6597 0.269204 41.4645 0.464466C41.2692 0.659728 41.2692 0.976311 41.4645 1.17157L44.2929 4L41.4645 6.82843C41.2692 7.02369 41.2692 7.34027 41.4645 7.53553C41.6597 7.7308 41.9763 7.7308 42.1716 7.53553L45.3536 4.35355ZM0 4.5H45V3.5H0V4.5Z"
                              fill="currentColor"
                            />
                          </svg>
                          <svg
                            width="46"
                            height="8"
                            viewBox="0 0 46 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M45.3536 4.35355C45.5488 4.15829 45.5488 3.84171 45.3536 3.64645L42.1716 0.464466C41.9763 0.269204 41.6597 0.269204 41.4645 0.464466C41.2692 0.659728 41.2692 0.976311 41.4645 1.17157L44.2929 4L41.4645 6.82843C41.2692 7.02369 41.2692 7.34027 41.4645 7.53553C41.6597 7.7308 41.9763 7.7308 42.1716 7.53553L45.3536 4.35355ZM0 4.5H45V3.5H0V4.5Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-right p-relative">
                <div className="about-img text-md-end">
                  <img src={img} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- about about end--> */}
    </>
  );
};

export default TopAbout;

import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../context/ContextProvider";

const AnimateMouse = () => {
  const { isEnter } = useContext(AppContext);
  const eRef = useRef(null);
  const tRef = useRef(null);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1024); // Change 1024 to the width threshold you prefer
  let n, i = 0, o = false;

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1024);
    };

    const handleMouseMove = (s) => {
      if (!o) {
        tRef.current.style.transform = `translate(${s.clientX}px, ${s.clientY}px)`;
      }
      eRef.current.style.transform = `translate(${s.clientX}px, ${s.clientY}px)`;
      n = s.clientY;
      i = s.clientX;
    };

    if (isLargeScreen) {
      window.addEventListener("mousemove", handleMouseMove);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("resize", handleResize);
    };
  }, [isLargeScreen]);

  if (!isLargeScreen) return null; // Do not render anything if screen is not large

  return (
    <React.Fragment>
      <div
        ref={eRef}
        style={{ visibility: "visible" }}
        className={`mouseCursor cursor-outer ${isEnter ? "cursor-big" : ""}`}
      ></div>
      <div
        ref={tRef}
        className={`mouseCursor cursor-inner ${isEnter ? "cursor-big" : ""}`}
        style={{ visibility: "visible" }}
      >
        <span>Drag</span>
      </div>
    </React.Fragment>
  );
};

export default AnimateMouse;

import React from "react";
import FooterThree from "../../common/Footer/FooterThree";
import PortfolioBanner from "./PortfolioBanner";
import PortfolioItems from "./PortfolioItems";

const Portfolio = () => {
  return (
    <>
      <PortfolioBanner />
      <PortfolioItems />
      <FooterThree />
    </>
  );
};

export default Portfolio;

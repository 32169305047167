// MobileData.js

const menu_data = [
  {
    title: "Home",
    link: "/",
    hasDropdown: false,
  },
  {
    title: "About us",
    link: "/about",
    hasDropdown: false,
  },
  {
    title: "Service",
    link: "/service",
    hasDropdown: false,
  },
  {
    title: "Contact",
    link: "/contact",
    hasDropdown: false,
  },
  // other menu items...
];

export default menu_data;

import React from "react";
import img from "../../../img/kristina.JPG"

const AboutCompany = () => {
  return (
    <>
      <div className="about-details-page pt-140 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-details-info pr-65">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle mb-25">
                    About our Company
                  </span>
                  <h2 className="tp-pt-size mb-40">
                    Our business boasts top of the line services
                  </h2>
                </div>
                <div className="about-tb-content">
                  <nav>
                    <div className="nav mb-30" id="nav-tab" role="tablist">
                      <button
                        className="nav-links active"
                        id="mission"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-mission"
                        type="button"
                        role="tab"
                        aria-controls="nav-mission"
                        aria-selected="true"
                      >
                        Our Mission
                      </button>
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-mission"
                      role="tabpanel"
                      aria-labelledby="nav-mission"
                    >
                      <p>
                        At Links & Likes, we are not just a digital marketing company — our goal is to become quality and dedicated partners for your business. Whether you aim at getting online traction, enhancing user engagement or promote your product/service to maximize its conversions; we have it all covered. Together, we'll make sure your business profits in the digital landscape. We are committed to being the trusted partner for every small and medium business owner, who wants to thrive in digital. We create unforgettable digital moments that draw your audience and turns them into diehard clients.
                      </p>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 text-end">
              <div className="tp-about-img-box p-relative d-inline-block mb-30">
                <div className="about-page-img">
                  <img src={img} alt="" />
                  {/* <img src="assets/img/about/kristina.jpeg" alt="" style={{ width: 'auto', height: '800px' }} /> */}

                </div>
                <div className="dots-img dots-img-lg">
                  <img src="assets/img/about/dot.jpg" alt="" />
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCompany;

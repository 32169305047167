import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import slider from "../../data/SliderHomePageOne.json";
import { IoIosArrowBack } from "react-icons/io";

const HomeSlider = () => {
  const sliderRef = useRef(null);

  const settings = {
    autoplay: true,
    autoplaySpeed: 4000,
    dots: false,
    fade: true,
    arrows: false, // Custom arrows are used, so we disable slick's default arrows
    responsive: [
      {
        breakpoint: 1024, // For tablets
        settings: {
          arrows: false,
          dots: false, // Enable dots for better navigation on smaller screens
        },
      },
      {
        breakpoint: 768, // For smaller tablets and large phones
        settings: {
          arrows: false,
          dots: false,
          fade: false, // Disable fade for a smoother sliding experience
        },
      },
      {
        breakpoint: 576, // For mobile screens
        settings: {
          arrows: false,
          dots: false,
          fade: false,
          autoplay: true,
        },
      },
    ],
  };

  return (
    <div className="slider-home">
      <div className="tp-slider-area p-relative">
        <div className="tp-hero_slider slider-active p-relative">
          <Slider ref={sliderRef} {...settings}>
            {slider?.slider.map((item) => (
              <div key={item.id}>
                <div
                  className="tp-single-slider tp-slider-height p-relative"
                  style={{
                    backgroundImage: `url(${item.bgImg})`,
                  }}
                >
                  <div className="tp-slider-text d-inline-block">
                    {/* <span className="mb-15 d-inline-block">{item.title}</span> */}
                    <h3 className="tp-slider-title">
                      {item.subTitle1} <br />
                      {item.subTitle2}
                    </h3>

                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        {/* Custom navigation arrows */}
        {/* <div className="slider-arrow">
          <button
            onClick={() => sliderRef.current?.slickNext()}
            type="button"
            className="slick-prev slick-arrow"
          >
            <span>Next</span>
          </button>
          <button
            onClick={() => sliderRef.current?.slickPrev()}
            type="button"
            className="slick-next slick-arrow"
          >
            <span>Prev</span>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default HomeSlider;

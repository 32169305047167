import React, { useState } from "react";

import useSticky from "../../../hooks/use-sticky";
import Menus from "./Menus";
import OffCanvas from "./OffCanvas";
import GoogleTranslate from "../../googleTranslate/GoogleTranslate";

const Menu = ({ header_style_2 }) => {
  const [isOffCanvas, setOffCanvas] = useState(false);
  const { sticky } = useSticky();
  return (
    <>
      <header>

        <div
          id="header-sticky"
          className={`header__area ${header_style_2
            ? "header__area-2 pl-110 pr-110"
            : "header__transparent pr-90 pl-90"
            } header-sticky-active ${sticky ? "header-sticky" : ""}`}
        >
          <div className="header__main" id="header-sticky">
            <div className="pe-4 lg:pe-0">
              <div className="d-flex justify-content-between align-items-center">
                <div className="col-lg-2 col-md-4 col-7">
                  <div className="logo">
                    {/* <Link to="/">
                      <img
                        src={

                          // "/assets/img/myImg/logo1.png"
                          "/assets/img/myImg/logoTransparent.png"
                        }
                        alt="logo"
                      />
                    </Link> */}
                  </div>
                </div>
                <div className=" col-lg-7 col-md-4 d-none d-md-block" >
                  <div
                    className={`main-menu ${header_style_2 ? "" : ""
                      } text-center`}
                  >
                    <nav id="mobile-menu" className="d-none d-md-block">
                      <Menus />
                    </nav>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-5">
                  <div className="header__main_right d-flex align-items-center">
                    <div className="translate-wrapper border-0 text-dark bg-opacity-10">
                      <GoogleTranslate />
                    </div>
                    <div
                      className={`tp-bar-icon ${header_style_2 ? "tp-bar-icon-dark" : ""
                        }`}
                    >
                      <button
                        onClick={() => setOffCanvas(true)}
                        className="offcanvas-toggle-btn d-md-none "
                      >
                        <i className="fal fa-bars" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* off canvas start */}
      <OffCanvas isOffCanvas={isOffCanvas} setOffCanvas={setOffCanvas} />
      {/* off canvas end */}
    </>
  );
};

export default Menu;

import React from "react";
import { ServiceItem } from "../../HomeTwo/Services";
import serviceData from '../../../data/Service.json';

const OurService = () => {
  const truncateDesc = (desc) => {
    return desc.length > 70 ? desc.slice(0, 70) + "..." : desc;
  };

  return (
    <>
      <div className="tp-service-ara grey-bg-4 pt-140">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center mb-70">
                <span className="tp-section-subtitle mb-30">OUR SERVICES</span>
                <h2 className="tp-section-title">Explore Our Services</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {serviceData.services.map((service) => {
              console.log("Service Item:", service.id);
              return (
                <ServiceItem
                  id={service.id}
                  icon={service.icon}
                  title={service.title}
                  desc={truncateDesc(service.description)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default OurService;

import React from "react";
import { useParams } from "react-router-dom"; // Import useParams
import Contact from "../../common/Contact/Contact";
import FooterThree from "../../common/Footer/FooterThree";
import ServiceDetailsAbout from "./ServiceDetailsAbout";
import ServiceDetailsBanner from "./ServiceDetailsBanner";

const ServiceDetails = () => {
  const { id } = useParams(); // Use useParams to get the id
  console.log(id);

  return (
    <>
      <ServiceDetailsBanner />
      <ServiceDetailsAbout id={id} /> {/* Pass id as a prop */}
      <Contact />
      <FooterThree />
    </>
  );
};

export default ServiceDetails;

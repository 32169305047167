import React from "react";
import Contact from "../../common/Contact/Contact";
import Testimonial from "../../common/Testimonial/Testimonial";
import OurService from "./OurService";
import ServiceBanner from "./ServiceBanner";
import FooterThree from "../../common/Footer/FooterThree";

const Service = () => {
  return (
    <>
      <ServiceBanner />
      <OurService />
      <Contact />
      <Testimonial />
      <FooterThree />
    </>
  );
};

export default Service;
